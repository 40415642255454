import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "tw-w-10 tw-fixed tw-z-50 tw-bottom-10 tw-right-10 tw-py-5 tw-px-2 tw-bg-on-surface tw-rounded-full tw-cursor-pointer tw-text-surface",
    on: {
      "click": function click($event) {
        _vm.$nuxt.$vuetify.theme.dark = !_vm.$nuxt.$vuetify.theme.dark;
      }
    }
  }), _vm._v(" "), _c('h1', [_vm._v("Color")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-items-start tw-content-start tw-gap-4 tw-h-full tw-flex-wrap tw-break-word"
  }, [_c('div', {
    staticClass: "tw-flex tw-flex-col tw-gap-1 tw-w-[49%]"
  }, [_c('h2', {
    staticClass: "tw-mt-4 tw-mb-4"
  }, [_vm._v("#Vuetify")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-wrap tw-align-top tw-gap-1"
  }, _vm._l(_setup.colors, function (color, index) {
    return _c('div', {
      key: index,
      staticClass: "tw-border tw-border-black"
    }, [_c('div', {
      class: "".concat(color[0], " ").concat(color[1], "--text tw-w-[100px] tw-h-[100px] tw-text-wrap tw-flex tw-items-center tw-justify-center tw-text-center")
    }, [_vm._v("\n            " + _vm._s(color[0]) + "\n          ")])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-wrap tw-align-top tw-gap-1"
  }, _vm._l(_setup.colors, function (color, index) {
    return _c('div', {
      key: index,
      staticClass: "tw-border tw-border-black"
    }, [_c('div', {
      class: "".concat(color[0], "--text ").concat(color[1], " tw-w-[100px] tw-h-[100px] tw-text-wrap tw-flex tw-items-center tw-justify-center tw-text-center")
    }, [_vm._v("\n            " + _vm._s(color[0]) + "--text\n          ")])]);
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-col tw-gap-1 tw-w-[49%]"
  }, [_c('h2', {
    staticClass: "tw-mt-4 tw-mb-4"
  }, [_vm._v("#Tailwind")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-wrap tw-align-top tw-gap-1"
  }, _vm._l(_setup.twColors, function (color, index) {
    return _c('div', {
      key: index,
      staticClass: "tw-border tw-border-black"
    }, [_c('div', {
      class: "".concat(color[0], " ").concat(color[1], " tw-w-[100px] tw-h-[100px] tw-text-wrap tw-flex tw-items-center tw-justify-center tw-text-center")
    }, [_vm._v("\n            " + _vm._s(color[0]) + "\n          ")])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-wrap tw-align-top tw-gap-1"
  }, _vm._l(_setup.twReverseColors, function (color, index) {
    return _c('div', {
      key: index,
      staticClass: "tw-border tw-border-black"
    }, [_c('div', {
      class: "".concat(color[0], " ").concat(color[1], " tw-w-[100px] tw-h-[100px] tw-text-wrap tw-flex tw-items-center tw-justify-center tw-text-center")
    }, [_vm._v("\n            " + _vm._s(color[0]) + "\n          ")])]);
  }), 0)])]), _vm._v(" "), _c('hr', {
    staticClass: "tw-border-t tw-my-4"
  }), _vm._v(" "), _c('h1', [_vm._v("Text")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-items-start tw-content-start tw-gap-4 tw-h-full tw-flex-wrap tw-break-word"
  }, [_c('div', {
    staticClass: "tw-flex tw-flex-col tw-align-top tw-gap-1 tw-w-[49%]"
  }, [_c('h2', {
    staticClass: "tw-mt-4 tw-mb-4"
  }, [_vm._v("#Vuetify")]), _vm._v(" "), _vm._l(_setup.fonts, function (font, index) {
    return _c('div', {
      key: index,
      class: "".concat(font, " tw-w-fit")
    }, [_vm._v("\n        " + _vm._s(font) + "\n      ")]);
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-flex-col tw-align-top tw-gap-1 tw-w-[49%]"
  }, [_c('h2', {
    staticClass: "tw-mt-4 tw-mb-4"
  }, [_vm._v("#Tailwind")]), _vm._v(" "), _vm._l(_setup.twFonts, function (font, index) {
    return _c('div', {
      key: index,
      class: "".concat(font, " tw-w-fit")
    }, [_vm._v("\n        " + _vm._s(font) + "\n      ")]);
  })], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };